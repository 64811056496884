.ServiceDetailsWebProgramming--main_container{
    display: flex;
    box-sizing: border-box;
    width: 100vw;
}
.ServiceDetailsWebProgramming--title_description_container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width:70vw;
}
.ServiceDetailsWebProgramming--section_title{
    font-family: 'Exo 2', sans-serif;
    font-size: 48px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.3em;
    padding-bottom: 10px;
}
.ServiceDetailsWebProgramming--section_description{
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    color: #343434;
    letter-spacing: 1px;
    line-height: 1.3em;
    padding-bottom: 10px;
}
.ServiceDetailsWebProgramming--logo_container{
    display: flex;
    width: 20vw;
    height: 20vw;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 20px;
    margin-top: 100px;
}
.ServiceDetailsWebProgramming--logo{
    box-sizing: border-box;
}

.ServiceDetailsWebProgramming--action_button_container{
    box-sizing: border-box;
    display: flex;
    margin-top: 50px;
}
.ServicesDetailWeb--contact_form_modal_container{
    display: flex;
    box-sizing: border-box;
}