.contact_method-main_container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    margin-right: 5px;
}
.contact_method-contact_icon_container{
    display: flex;
    box-sizing: border-box;
    width: 90%;
    justify-content: space-between;
}
.contact_method-label{
    font-family: 'PT Sans', sans-serif;
    font-size: 24px;
}
.contact_method-icons_container{
    display: flex;
}
.mail_icon{
    color: #0E4DE3;
    cursor: pointer;
}
.mail_icon_disabled{
    color: #5a5b5f;
    cursor: pointer;
}
.phone_icon{
    color: #0E4DE3;
    cursor: pointer;
}
.phone_icon_disabled{
    color: #5a5b5f;
    cursor: pointer;
}
.contact_method-input{
    height: 35px;
    width: 300px;
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
}
.contact_method-error_text{
    font-family: 'PT Sans', sans-serif;
    font-size: 12px;
    color: red;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .contact_method-input{
        height: 18px;
        width: 200px;
        font-family: 'PT Sans', sans-serif;
        font-size: 18px;
    }
    .contact_method-label{
        font-size: 18px;
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .contact_method-input{
        height: 20px;
        width: 220px;
        font-family: 'PT Sans', sans-serif;
        font-size: 20px;
    }
    .contact_method-label{
        font-size: 20px;
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*Medium*/
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}