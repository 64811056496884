  .cta_section-main_container {
  display: flex;
  width: 100vw;
  height: fit-content;
  position: relative;
  background-image: url("/public/ChamferBottomRight.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 50px;
}

.cta_section-welcome_title_description_action_container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 5vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 30vw;
    box-sizing: border-box;
}

.cta_section-welcome{
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
    color: #FDBA5F ;
    text-transform: uppercase;
    text-align: left;
}

.cta_secttion-title{
    display: flex;
    font-family: 'Bungee Hairline', sans-serif;
    font-size: 50px;
    color: #ffffff ;
    text-transform: uppercase;
    text-align: left;
    font-weight: 900;
    line-height: 1em;
}

.cta_secttion-description{
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    text-align: left;
    color: #ffffff ;
}

.cta_section-carrusel_container{
    width: 70vw;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
  .cta_section-main_container {
    background-image: url("/public/Square.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cta_secttion-title{
    font-size: 30px;
}
.cta_secttion-description{
  font-size: 12px;
}
.cta_section-carrusel_container{
  display: none;
}
.cta_section-welcome_title_description_action_container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 80vw;
}
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta_secttion-title{
    font-size: 30px;
}
.cta_secttion-description{
  font-size: 16px;
}
.cta_section-carrusel_container{
  display: none;
}
.cta_section-welcome_title_description_action_container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 80vw;
}
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {

.cta_section-carrusel_container{
  display: none;
}
.cta_section-welcome_title_description_action_container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 80vw;
}
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta_section-main_container {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: relative;
    background-image: url("/public/ChamferBottomRight.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  .cta_section-welcome_title_description_action_container{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 5vw;
      padding-top: 5vh;
      padding-bottom: 5vh;
      width: 40vw;
  }
  
  .cta_section-welcome{
      font-family: 'Exo 2', sans-serif;
      font-size: 14px;
      color: #FDBA5F ;
      text-transform: uppercase;
      text-align: left;
  }
  
  .cta_secttion-title{
      display: flex;
      font-family: 'Bungee Hairline', sans-serif;
      font-size: 50px;
      color: #ffffff ;
      text-transform: uppercase;
      text-align: left;
      font-weight: 900;
      line-height: 1em;
  }
  
  .cta_secttion-description{
      font-family: 'PT Sans', sans-serif;
      font-size: 18px;
      text-align: left;
      color: #ffffff ;
  }
  
  .cta_section-carrusel_container{
      width: 50vw;
  }
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
  /*Extra extra large*/
}

@media only screen and (max-height: 414px) and (orientation: landscape)  {
  .cta_section-main_container {
    height: 600px;
  }
}


/*Solo para el carrusel, permitir que se vea desde 1024*/
@media only screen and (min-width: 1024px) and (max-width: 1680px ) and (orientation: landscape){
  .cta_section-carrusel_container{
    width: 30vw;
}
}

/*Solo para el carrusel, ocultar para menores de 1024*/
@media only screen and (max-width: 1023px) and (orientation: landscape){
  .cta_section-carrusel_container{
    display: none;
}
}
