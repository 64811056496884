.action_button-container {
  display: flex;
  border-radius: 3px;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 10px 10px;
  margin-right: auto;
  box-sizing: border-box;
}
.pepe{
  border: 5px;
  border-style: solid;
  border-color: aqua;
}
.action_button {
  border: none;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.chevron {
  display: flex;
  color: black;
}

.chevron_hover {
  color: black;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
  .action_button-container {
    display: flex;
    border-radius: 3px;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 2px 2px;
    margin-right: auto;
    width: fit-content;
    box-sizing: border-box;
  }
  .action_button {
    font-family: "Exo 2", sans-serif;
    font-size: 10px;
  }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .action_button-container {
    display: flex;
    border-radius: 3px;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 2px 2px;
    margin-right: 20px;
    box-sizing: border-box;
    width: fit-content;
  }
  .action_button {
    font-family: "Exo 2", sans-serif;
    font-size: 10px;
  }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .action_button-container {
        display: flex;
        border-radius: 3px;
        letter-spacing: 1px;
        cursor: pointer;
        padding: 2px 2px;
        margin-right: 20px;
        box-sizing: border-box;
        width: fit-content;
      }
      .action_button {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
      }
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .action_button-container {
        display: flex;
        border-radius: 3px;
        letter-spacing: 1px;
        cursor: pointer;
        padding: 2px 2px;
        margin-right: 20px;
        box-sizing: border-box;
        width: fit-content;
      }
      .action_button {
        font-family: "Exo 2", sans-serif;
        font-size: 13px;
      }
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
  /*Extra extra large*/
}
