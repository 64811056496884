.contact_form-main_container{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}
.contact_form-contact_method_subject_container{
    display: flex;
    flex-wrap: wrap;
}
.contact_form-message_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.contact_form-message_label{
    font-family: 'PT Sans', sans-serif;
    font-size: 24px;
}
.contact_form-message_text{
    box-sizing: border-box;
    width: 100%;
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    height: 70%;
}
.contact_form_action_buttons_container{
    display: flex;
    margin-top: 10px;
}
/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .contact_form-message_label{
        font-family: 'PT Sans', sans-serif;
        font-size: 18px;
    }
    .contact_form-message_text{
        box-sizing: border-box;
        width: 100%;
        font-family: 'PT Sans', sans-serif;
        font-size: 18px;
        height: 150px;
    }
    .contact_form-main_container{
        margin-top: 50px;
    }
    .contact_form_action_buttons_container{
        margin-top: 50px;
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    /*Small*/
    .contact_form-message_label{
        font-family: 'PT Sans', sans-serif;
        font-size: 20px;
    }
    .contact_form-message_text{
        box-sizing: border-box;
        width: 100%;
        font-family: 'PT Sans', sans-serif;
        font-size: 20px;
        height: 150px;
    }
    .contact_form-main_container{
        margin-top: 50px;
    }
    .contact_form_action_buttons_container{
        margin-top: 50px;
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*Medium*/
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}