.lets_start_section-main_container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
}
.lets_start_section-section_name{
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    color: #0e4de3;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 50px;
}
.lets_start_section-section_content_contact_form_container{
    display: flex;
    box-sizing: border-box;
}
.lets_start_section-section_content_container{
    display: flex;
    flex-direction: column;
    width: 50vw;
    box-sizing: border-box;
}
.lets_start_section-section_heading{
    font-family: 'Exo 2', sans-serif;
    font-size: 48px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.3em;
    padding-bottom: 10px;
    margin-bottom: 50px;
}
.lets_start_section-section_description{
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    color: #666;
    letter-spacing: 1px;
    line-height: 1.3em;
    margin-bottom: 50px;
}
.lets_start_section-contact_form_container{
    display: flex;
    width: 50vw;
    box-sizing: border-box;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .lets_start_section-section_content_contact_form_container{
        display: inline-block;
        box-sizing: border-box;
    }
    .lets_start_section-section_heading{
        font-size: calc(100vw*0.06);
    }
    .lets_start_section-section_content_container{
        width: 100vw;
    }
    .lets_start_section-contact_form_container{
        display: flex;
        width: 100vw;
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .lets_start_section-section_content_contact_form_container{
        display: inline-block;
        box-sizing: border-box;
    }
    .lets_start_section-section_heading{
        font-size: calc(100vw*0.06);
    }
    .lets_start_section-section_content_container{
        width: 100vw;
    }
    .lets_start_section-contact_form_container{
        display: flex;
        width: 100vw;
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*Medium*/
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}