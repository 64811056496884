.main_menu-container{
        display: flex;
        align-items: center;
}
.main_menu_button{
    background-color: white;
    border: none;
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
}
button.main_menu_button:hover{
    color: rgb(76, 77, 77);
}

/*RESPONSIVENESS STARTS HERE ;)*/
@media screen and (max-width: 980px) {
    .main_menu-container{
        display: none;
    }
}