.combo_box_input-main_container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: flex-start;
    box-sizing: border-box;
}
.combo_box_input-label{
    font-family: 'PT Sans', sans-serif;
    font-size: 24px; 
}
.combo_box_input-combo_box{
    height: 41px;
    width: 300px;
    font-family: 'PT Sans', sans-serif;
    font-size: 18px; 
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .combo_box_input-label{
        font-size: 18px; 
    }
    .combo_box_input-combo_box{
        height: 25px;
        width: 300px;
        font-family: 'PT Sans', sans-serif;
        font-size: 18px; 
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .combo_box_input-label{
        font-size: 20px; 
    }
    .combo_box_input-combo_box{
        height: 25px;
        width: 300px;
        font-family: 'PT Sans', sans-serif;
        font-size: 20px; 
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*Medium*/
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}