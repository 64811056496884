:root {
    --header_logo-width: 80px;
    --header_logo-height: 80px;
    --header_title_font_size:25px;
    --header_title_font_weight:350;
  }
.header-title_logo_container{
    display: flex;
    align-items: center;
    height: 55px;
}

.header-title{
    font-family: 'Exo 2', sans-serif;
    font-size: var(--header_title_font_size);
    font-weight: var(--header_title_font_weight);
}

.header-logo{
    width: var(--header_logo-width);
    height: var(--header_logo-height);
}

/*RESPONSIVENESS STARTS HERE ;)*/
@media screen and (max-width: 980px) {
    .header-title{
        font-family: 'Exo 2', sans-serif;
        font-size: calc(var(--header_title_font_size)*0.8) ;
        font-weight: var(--header_title_font_weight);
    }

    .header-logo{
        width: calc(var(--header_logo-width)*0.8) ;
        height: calc(var(--header_logo-width)*0.8) ;
    }
}