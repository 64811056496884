:root {
    --mobile_main_menu-logo_font_size: 50px;
    --mobile_main_menu-logo_color: #1f6dc7;
    --mobile_main_menu-logo_color_hover: #686a70;
  }
.mobile_main_menu-container{
    display: flex;
    flex-direction: column;
    width: 135px;
    position: relative;
    justify-content: center;
}

.mobile_main_menu-menu_icon_container{
    display: flex;
    justify-content: flex-end;
}
.mobile_main_menu-options_container{
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    top: 70px;    
}
.mobile_main_menu-options_container-hidden{
    display: none;
}

.mobile_main_menu-menu_button{
    border: none;
    background-color: white;
    text-align: right;
    margin-top: 2px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    cursor: pointer;
}

button.mobile_main_menu-menu_button:hover{
    color: rgb(76, 77, 77);
}

.material-icons.menu_icon { 
    color: var(--mobile_main_menu-logo_color);
    font-size: var(--mobile_main_menu-logo_font_size);
    cursor: pointer;}
.material-icons.menu_icon:hover { color: var(--mobile_main_menu-logo_color_hover);}

/*RESPONSIVENESS STARTS HERE ;)*/
@media screen and (min-width: 980px) {
    .mobile_main_menu-container{
        display: none;
    
    }
}