.our_team_section-main_container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  position: relative;
  background-image: url("/public/ChamferLeft.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 40px;
  margin-top: 100px;
  box-sizing: border-box;
}
.our_team_section-title{
    font-family: 'Exo 2', sans-serif;
    font-size: 48px;
    text-align: center;
    text-transform: capitalize;
    color: white;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 500;
}
.our_team_section-team_members_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
}


.our_team_section-team_members_carousel_container{
    display: none;
    width: 100vw;
}




/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .our_team_section-main_container {
        background-image: url("/public/Square.svg");
        height: 200px;
      }
      .our_team_section-title{
        font-size: 30px;
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
    }
    .our_team_section-team_members_carousel_container{
        display: flex;
        justify-content: center;
    }
    .our_team_section-team_members_container{
        display: none;
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .our_team_section-main_container {
        background-image: url("/public/Square.svg");
        height: 200px;
      }
      .our_team_section-title{
        font-size: 40px;
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
    }
    .our_team_section-team_members_carousel_container{
        display: flex;
        justify-content: center;
    }
    .our_team_section-team_members_container{
        display: none;
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .our_team_section-main_container {
        background-image: url("/public/Square.svg");
        height: 220px;
      }
      .our_team_section-title{
        font-size: 40px;
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
    }
    .our_team_section-team_members_carousel_container{
        display: flex;
        justify-content: center;
    }
    .our_team_section-team_members_container{
        display: none;
    }
      .our_team_section-title{
        font-size: 40px;
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
    }
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .our_team_section-title{
        font-size: 40px;
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
    }
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}