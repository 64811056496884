/* src/Carousel.css */
.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100vw;
  }
  
  .carousel button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  
  .carousel img {
    max-width: 300px;
    max-height: 200px;
  }