body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section_name {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
  color: #0e4de3;
  font-weight: 500;
  letter-spacing: 1px;
}
.section_title {
  font-family: 'Exo 2', sans-serif;
  font-size: 48px;
  text-align: left;
  color: #000000;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.3em;
  padding-bottom: 10px;
}
.section_sub_title {
  font-family: 'Exo 2', sans-serif;
  font-size: 30px;
  text-align: left;
  color: rgb(53, 51, 51);
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.3em;
  padding-bottom: 10px;
}
.section_paragraph {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  text-align: left;
  color: #343434;
  letter-spacing: 1px;
  line-height: 1.3em;
  padding-bottom: 10px;
}

.banner--title{
  display: flex;
    font-family: 'Bungee Hairline', sans-serif;
    font-size: 40px;
    color: #ffffff ;
    text-transform: uppercase;
    text-align: left;
    font-weight: 900;
    line-height: 1em;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
  /*PORTRAIT*/
  .banner--title{
      font-size: 30px;
  }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
  .banner--title{
    font-size: calc(100vh*(30/375));
}
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
  /*LANDSCAPE*/
}