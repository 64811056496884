.mission_vision_section-main_container{
    display: flex;
    box-sizing: border-box;
    width: 100vw;
}
.mission_vision_section-title_description_container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 100px;
}
.mission_vision_section-section_name{
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    color: #0e4de3;
    font-weight: 500;
    letter-spacing: 1px;
}
.mission_vision_section-section_title{
    font-family: 'Exo 2', sans-serif;
    font-size: 48px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.3em;
    padding-bottom: 10px;
}
.mission_vision_section-section_description{
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    color: #343434;
    letter-spacing: 1px;
    line-height: 1.3em;
    padding-bottom: 10px;
}
.mission_vision_section-logo_container{
    display: flex;
}
.mission_vision_section-logo{
    width: 400px;
    height: 400px;
    padding: 50px;
    box-sizing: border-box;
}

.mission_vision_section-action_button_container{
    box-sizing: border-box;
    display: flex;
    margin-top: 50px;
}

/*RESPONSIVENESS STARTS HERE ;)*/

/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
    /*PORTRAIT*/
    .mission_vision_section-main_container{
        display: inline-block;
    }
    .mission_vision_section-logo_container{
        justify-content: center;
    }
    .mission_vision_section-section_title{
        font-size: calc(100vw*0.06);
    }
    .mission_vision_section-logo{
        width: calc(100vw*0.3);
        height: calc(100vw*0.3);
        padding: 0px;
        box-sizing: border-box;
    }
    .mission_vision_section-title_description_container{
        padding: 30px;
    }

    .mission_vision_section-section_description{
        font-size: calc(100vw*0.05);
    }
}
@media only screen and (max-width: 575px) and (orientation:landscape) {
    /*LANDSCAPE*/
    .mission_vision_section-main_container{
        display: inline-block;
    }
    .mission_vision_section-logo_container{
        justify-content: center;
    }
    .mission_vision_section-section_title{
        font-size: calc(100vw*0.02);
    }
    .mission_vision_section-logo{
        width: calc(100vw*0.25);
        height: calc(100vw*0.25);
        padding: 0px;
        box-sizing: border-box;
    }
    .mission_vision_section-title_description_container{
        padding: 30px;
    }

    .mission_vision_section-section_description{
        font-size: calc(100vw*0.028);
    }
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
    /*PORTRAIT*/
    .mission_vision_section-main_container{
        display: inline-block;
    }
    .mission_vision_section-logo_container{
        justify-content: center;
    }
    .mission_vision_section-section_title{
        font-size: calc(100vw*0.06);
    }
    .mission_vision_section-logo{
        width: calc(100vw*0.3);
        height: calc(100vw*0.3);
        padding: 0px;
        box-sizing: border-box;
    }
    .mission_vision_section-title_description_container{
        padding: 30px;
    }

    .mission_vision_section-section_description{
        font-size: calc(100vw*0.05);
    }
}
/*LANDSCAPE*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:landscape){
    /*LANDSCAPE*/
    .mission_vision_section-main_container{
        display: inline-block;
    }
    .mission_vision_section-logo_container{
        justify-content: center;
    }
    .mission_vision_section-section_title{
        font-size: calc(100vh*0.06);
    }
    .mission_vision_section-logo{
        width: calc(100vw*0.25);
        height: calc(100vw*0.25);
        padding: 0px;
        box-sizing: border-box;
    }
    .mission_vision_section-title_description_container{
        padding: 30px;
    }

    .mission_vision_section-section_description{
        font-size: calc(100vw*0.028);
    }
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
    /*PORTRAIT*/
    .mission_vision_section-main_container{
        display: inline-block;
    }
    .mission_vision_section-logo_container{
        justify-content: center;
    }
    .mission_vision_section-section_title{
        font-size: calc(100vw*0.06);
    }
    .mission_vision_section-logo{
        width: calc(100vw*0.3);
        height: calc(100vw*0.3);
        padding: 0px;
        box-sizing: border-box;
    }
    .mission_vision_section-title_description_container{
        padding: 30px;
    }

    .mission_vision_section-section_description{
        font-size: calc(100vw*0.05);
    }
}
/*LANDSCAPE*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:landscape){
    /*LANDSCAPE*/
    /*LANDSCAPE*/
    .mission_vision_section-main_container{
        display: inline-block;
    }
    .mission_vision_section-logo_container{
        justify-content: center;
    }
    .mission_vision_section-section_title{
        font-size: calc(100vh*0.06);
    }
    .mission_vision_section-logo{
        width: calc(100vw*0.25);
        height: calc(100vw*0.25);
        padding: 0px;
        box-sizing: border-box;
    }
    .mission_vision_section-title_description_container{
        padding: 30px;
    }

    .mission_vision_section-section_description{
        font-size: calc(100vw*0.022);
    }
}