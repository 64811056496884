.team_member_chip-main_container{
    display: flex;
    width: 350px;
    height: fit-content;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    margin: 10px;
    
}
.team_member_chip-name_position_bio_avatar_container{
    display: flex;
}
.team_member_chip-name_position_bio_container{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
}
.team_member_chip-name{
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    color: #62748a;
    line-height: 1.5;
    font-weight: 500;
    text-align: left;

}
.team_member_chip-bio{
    display: none;/*OJO ESTEO LO POENS ASI PORQUE DE MOMENTO NO VAS A TENER BIO*/
    font-family: 'PT Sans', sans-serif;
    padding-bottom: 1em;
    font-style: italic;
    color: #62748a;
    line-height: 1.5;
    font-weight: 500;
    text-align: left;
}
.team_member_chip-position{
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    color: #62748a;
    text-align: left;
}
.team_member_chip-avatar{
    width:100px;
    height:100px;
    border-radius: 100%;
}
.team_member_chip-avtar_container{
    display: flex;
    align-content: center;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    /*X-Small*/
    .team_member_chip-avatar{
        width:50px;
        height:50px;
        border-radius: 100%;
    }
    .team_member_chip-avtar_container{
        display: flex;
        align-items: center;
    }
    .team_member_chip-main_container{
        width: 220px;        
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .team_member_chip-avatar{
        width:50px;
        height:50px;
        border-radius: 100%;
    }
    .team_member_chip-avtar_container{
        display: flex;
        align-items: center;
    }
    .team_member_chip-main_container{
        width: 220px;        
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .team_member_chip-avatar{
        width:80px;
        height:80px;
        border-radius: 100%;
    }
    .team_member_chip-avtar_container{
        display: flex;
        align-items: center;
    }
    .team_member_chip-main_container{
        width: 300px;        
    }
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .team_member_chip-avatar{
        width:80px;
        height:80px;
        border-radius: 100%;
    }
    .team_member_chip-avtar_container{
        display: flex;
        align-items: center;
    }
    .team_member_chip-main_container{
        width: 300px;        
    }
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}