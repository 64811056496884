.services_section-main_container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  position: relative;
  background-image: url("/public/TrapezoidRomboid.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
  box-sizing: border-box;
}
.services_section-product_service_chip_container {
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}
.services_section-title_container {
  display: flex;
  justify-content: center;
  width: 100vw;
  box-sizing: border-box;
}
.services_section-title {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  color: #fdba5f;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.7em;
  font-weight: 500;
}
.services_section-heading_container {
  display: flex;
  justify-content: center;
  width: 100vw;
  box-sizing: border-box;
}
.services_section-heading {
  font-family: "Exo 2", sans-serif;
  font-size: calc(40 * (100vw / 1600));
  color: white;
  font-weight: 600;
  line-height: 1.3em;
  text-align: center;
  padding-bottom: 10px;
  text-transform: capitalize;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .services_section-heading {
        font-size: calc(100vw*0.043);
      }
      .services_section-main_container {
        background-image: url("/public/Square.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0;
      }
    /*X-Small*/
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    /*Small*/
    .services_section-heading {
        font-size: calc(100vw*0.043);
      }
      .services_section-main_container {
        background-image: url("/public/Square.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0;
      }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*Medium*/
    .services_section-heading {
        font-size: calc(100vw*0.043);
      }
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
    .services_section-heading {
        font-size: calc(100vw*0.04);
      }
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}

/* ESTE LO AÑADI PAR DISPOSITIVOS DE PANTALLA NO MUY ANCHA EN MODO LANDSCAPE*/
@media only screen and (max-height: 414px) and (orientation: landscape)  {
  .services_section-heading {
    font-size: calc(100vw*0.025);
  }
}