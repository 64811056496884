.header-main_container{
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding-right: 20px;
    z-index: 200;
    background-color: white;
    width: 100vw;
    height: 75px;
    box-sizing: border-box;
}