  .ServicesPageBannerSection--main_container {
  display: flex;
  width: 100vw;
  height: fit-content;
  position: relative;
  background-image: url("/public/ChamferBottomRight.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: fit-content;
  box-sizing: border-box;
  padding-top: 50px;
}

.ServicesPageBannerSection--welcome_title_description_action_container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 5vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 30vw;
    box-sizing: border-box;
}

.ServicesPageBannerSection--welcome{
    font-family: 'Exo 2', sans-serif;
    font-size: 30px;
    color: #FDBA5F ;
    text-transform: uppercase;
    text-align: left;
}

.ServicesPageBannerSection--title{
    display: flex;
    font-family: 'Bungee Hairline', sans-serif;
    font-size: 40px;
    color: #ffffff ;
    text-transform: uppercase;
    text-align: left;
    font-weight: 900;
    line-height: 1em;
}

.ServicesPageBannerSection--carrusel_container{
    width: 75vw;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: fit-content;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
  /*PORTRAIT*/
  .cta_section-main_container {
    background-image: url("/public/Square.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cta_secttion-title{
    font-size: 30px;
}
.ServicesPageBannerSection--carrusel_container{
  display: none;
}
.cta_section-welcome_title_description_action_container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 80vw;
}
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
  /*PORTRAIT*/
  .cta_secttion-title{
    font-size: 30px;
}
.ServicesPageBannerSection--carrusel_container{
  display: none;
}
.cta_section-welcome_title_description_action_container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 80vw;
}
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
  /*PORTRAIT*/
  .ServicesPageBannerSection--carrusel_container{
    display: none;
  }
  .cta_section-welcome_title_description_action_container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 5vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 80vw;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
  /*PORTRAIT*/
  .cta_section-main_container {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: relative;
    background-image: url("/public/ChamferBottomRight.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  .cta_section-welcome_title_description_action_container{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 5vw;
      padding-top: 5vh;
      padding-bottom: 5vh;
      width: 40vw;
  }
  
  .cta_section-welcome{
      font-family: 'Exo 2', sans-serif;
      font-size: 14px;
      color: #FDBA5F ;
      text-transform: uppercase;
      text-align: left;
  }
  
  .cta_secttion-title{
      display: flex;
      font-family: 'Bungee Hairline', sans-serif;
      font-size: 50px;
      color: #ffffff ;
      text-transform: uppercase;
      text-align: left;
      font-weight: 900;
      line-height: 1em;
  }
  
  .ServicesPageBannerSection--carrusel_container{
      width: 50vw;
  }
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
  /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
  /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
  /*LANDSCAPE*/
}

@media only screen and (max-height: 414px) and (orientation: landscape)  {
  .cta_section-main_container {
    height: 600px;
  }
    .ServicesPageBannerSection--carrusel_container{
    width: 30vw;
}
}


/*Solo para el carrusel, permitir que se vea desde 1024*/
@media only screen and (min-width: 1024px) and (max-width: 1680px ) and (orientation: landscape){
  .ServicesPageBannerSection--carrusel_container{
    width: 30vw;
}
}

/*Solo para el carrusel, ocultar para menores de 1024*/
@media only screen and (max-width: 1023px) and (orientation: landscape){
  .ServicesPageBannerSection--carrusel_container{
    display: none;
}
}