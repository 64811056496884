.experiences_section-main_container{
    display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  position: relative;
  background-image: url("/public/Square.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 40px;
  margin-top: 100px;
  box-sizing: border-box;
}
.experiences_section-title{
    font-family: 'Exo 2', sans-serif;
    font-size: 48px;
    text-align: center;
    text-transform: capitalize;
    color: white;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 500;
    margin-top: 50px;
}

.experiences_section-colaboration_title{
    font-family: 'Exo 2', sans-serif;
    font-size: 48px;
    text-align: center;
    text-transform: capitalize;
    color: white;
    padding-bottom: 10px;
    line-height: 1em;
    font-weight: 500;
    margin-top: 50px;
}
.experiences_section-testimonials_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    
}
.experiences_section-collaboration_description{
    font-family: 'PT Sans', sans-serif;
    padding-bottom: 1em;
    font-style: italic;
    color: #ffffff;
    line-height: 1.5;
    font-weight: 500;
}
.experiences_section-collaborators_main_container{
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.experiences_section-collaborators_logo{
    border-radius: 10px;
    margin: 10px;
    filter: opacity(0.6);
}
.experiences_section-testimonials_carousel_container{
    display: none;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .experiences_section-title{
        font-size: 23px;
        margin-top: 15px;
    }

    /* 06/11/2023*/
    .experiences_section-testimonials_carousel_container{
        display: flex;
        position: absolute;
        top: 100px;
        width: 100vw;
    }
    .experiences_section-testimonials_container{
        display: none;
    }

    .experiences_section-colaboration_title{
        font-family: 'Exo 2', sans-serif;
        font-size: 23px;
        text-align: center;
        text-transform: capitalize;
        color: white;
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
        margin-top: 300px;
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .experiences_section-title{
        font-size: 34px;
    }
    
    /* 06/11/2023*/
    .experiences_section-testimonials_carousel_container{
        display: flex;
        position: absolute;
        top: 130px
    }
    .experiences_section-testimonials_container{
        display: none;
    }

    .experiences_section-colaboration_title{
        font-family: 'Exo 2', sans-serif;
        font-size: 34px;
        text-align: center;
        text-transform: capitalize;
        color: white;
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
        margin-top: 300px;
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*Medium*/
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}