.Tabs--main_container{
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    margin-top: 20px;
    min-height: 100vh;
}
.Tabs--container{
    display: flex;
    cursor: pointer;
}
.Tabs--content_container{
    display: flex;
}
.Tabs--tab{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-family: 'Exo 2', sans-serif;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-bottom: 5px;
}
.Tabs--tab_active{
    background-color: white;
    color: black;
    border-top: solid 1px;
    border-right: solid 1px;
    border-left: solid 1px;
}

.Tabs--tab_inactive{
    background-color: rgb(14, 77, 227);
    border-bottom: solid 2px black;
    border-right: solid 1px white;
    border-left: solid 1px white;
    color: white;
}
.Tabs--tab_inactive:hover{
    background-color: rgb(218, 218, 225);
}
/*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
    /*PORTRAIT*/
    .Tabs--container{
        display: flex;
        cursor: pointer;
        flex-wrap: wrap;
    }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
    /*LANDSCAPE*/
}