.footer_section_main_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #666666;
    box-sizing: border-box;
}
.FooterSection--company_name_year_container{
    display: flex;
    flex-direction: column;
}
.FooterSection--payment_methods_container{
    display: flex;
    justify-content: center;
    margin: 10px;
}
.footer_section_company_name{
    font-family: 'Exo 2', sans-serif;
    font-size: 20px;
    color: white;
}
.footer_section_year{
    font-family: 'Exo 2', sans-serif;
    font-size: 20px;
    color: white;
}