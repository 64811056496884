.products_section-main_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-top: 80px;
    box-sizing: border-box;
    padding: 5px;
}
.products_section-title_description_container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.products_section-title{
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.3em;
    text-align: center;
    color: #333;
    padding-bottom: 10px;
}
.products_section-description{
    font-family: 'PT Sans', sans-serif;
    text-align: center;
    line-height: 1.9em;
    color: #666;
    font-weight: 500;
}
.products_section-products_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .products_section-title{
        font-size: calc(100vw*0.065);
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    /*Small*/
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /*Medium*/
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}