.product_service_chip-main_container{
    display: flex;
    flex-direction: column;
    width: 425px;
    height: fit-content;
    min-height: 250px;
    box-sizing: border-box;
    padding: 10px;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    margin: 10px;
}
.product_service_chip-title_description_logo_container{
    display: flex;
}
.product_service_chip-title_description_container{
    display: flex;
    flex-direction: column;
}
.product_service_chip-action_buttons_container{
    display: flex;
}
.product_service_chip-title{
    font-family: 'Exo 2', sans-serif;
    font-size: 18px;
    color: #001955;
    line-height: 1em;
    font-weight: 500;
    text-align: left;

}
.product_service_chip-description{
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    color: #62748a;
    text-align: left;
}
.product_service_chip-logo{
    width:125px;
    height:125px;
    border-radius: 20px;
}

/*RESPONSIVENESS STARTS HERE ;)*/
/*X-Small*/
@media only screen and (max-width: 575px) {
    .product_service_chip-main_container{
        width: fit-content;
        height: fit-content;
        min-height: 200px;
    }
    .product_service_chip-logo{
        width:80px;
        height:80px;
        border-radius: 20px;
    }
    .product_service_chip-action_buttons_container{
        display: flex;
        width: 70%;
    }
}

/*Small*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .product_service_chip-main_container{
        width: fit-content;
        height: fit-content;
    }
    .product_service_chip-logo{
        width:80px;
        height:80px;
        border-radius: 20px;
    }
    .product_service_chip-action_buttons_container{
        display: flex;
        width: 50%;
    }
}

/*Medium*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .product_service_chip-main_container{
        width: 80%;
        height: fit-content;
    }
    .product_service_chip-logo{
        width:80px;
        height:80px;
        border-radius: 20px;
    }
    .product_service_chip-action_buttons_container{
        display: flex;
        width: 70%;
    }
}
/*Large*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    /*Large*/
}
/*Extra large*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    /*Extra large*/
}
/*Extra extra large*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) {
    /*Extra extra large*/
}